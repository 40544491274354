import { template as template_40e1a112c8ed41e5851e57e21f426f3b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_40e1a112c8ed41e5851e57e21f426f3b(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
