import { template as template_3aaf8786589d4c37baf9234bf9b3f44e } from "@ember/template-compiler";
const SidebarSectionMessage = template_3aaf8786589d4c37baf9234bf9b3f44e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
