import { template as template_6bc4e47e2fa84294a3fc598e384847d2 } from "@ember/template-compiler";
const FKLabel = template_6bc4e47e2fa84294a3fc598e384847d2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
